import React from "react"

import Layout from "../components/layout"
import "../css/index.css"

import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="main-content">
      <h2>Welcome</h2>
    </div>
  </Layout>
)

export default IndexPage
